<template>
  <div>
    <b-modal id="changeStatus" size="lg" title="Change Status" style="direction: rtl" centered hide-footer hide-header-close>
      <b-row class="d-flex flex-wrap" >
        <b-col v-for="(status, key) in allStatus" :key="key" class="col-4 text-black text-center mb-3 cursor-pointer" >
           <span
               :class="[{ 'shadow bg-primary text-white' : isSelected(status.value)},'d-inline-block w-100 p-1 custom-rounded  primary-color ']" @click="selectedStatusId = status.value">
            <i  :class="[{ 'las la-check-square' : isSelected(status.value)}]"></i> {{status.text}}
          </span>
          <span  class="d-block w-100 h-10 bg-white"></span>
          <span  :class="`status--${status.value} d-block w-100 h-10`">
          </span>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="saveEditStatus">Save</b-button>
      </div>
    </b-modal>

    <dashboard-page-title :showFilter="true" :showSearch="true" :showExportation="true" :showAddBtn="false">{{ $t('transportation.transportationInActive') }}</dashboard-page-title>
    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :additional-url="`&status=processing`" :list_url="'drivers'"  :reloadData="reloadData"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
import driverServices from '../services/transportation'
export default {
  name: 'drivers',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_TRANSPORTATION_LINK,
      reloadData: true,
      allStatus: [
        {
          value: 'pending',
          text: 'جارى الطلب'
        },
        {
          value: 'refused',
          text: 'تم الرفض'
        },
        {
          value: 'success',
          text: 'تم القبول'
        }
      ],
      saveButton: false,
      selectedStatusId: '',
      driverId: null,
      fields: [
        { label: 'اسم السائق', key: 'name', type: 'name', class: 'text-right' },
        { label: 'الشركة التابع لها', key: 'company', type: 'name', class: 'text-right' },
        { label: 'عدد الرحلات', key: 'numberOfTrips', class: 'text-right' },
        { label: 'التقييم', key: 'rate', type: 'number', class: 'text-right' },
        { label: 'الأرباح', key: 'profits', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            // {
            //   text: 'تغيير الحالة',
            //   icon: 'las la-sync',
            //   color: 'primary',
            //   actionName: 'showPopupChangeStatusOrder',
            //   actionParams: ['id']
            // },
            {
              text: 'show',
              icon: 'las la-eye',
              color: 'info',
              ifNavigate: true,
              routePath: 'show-inactive-drivers/:id'
            }
          ]
        }
      ]
    }
  },
  methods: {
    saveEditStatus () {
      this.saveButton = true
      driverServices.changeDriverStatus(this.driverId, this.selectedStatusId).then(res => {
        core.showSnackbar('success', 'تم تغير حالة الطلب بنجاح')
        this.saveButton = false
        this.$bvModal.hide('changeStatus')
      })
    },
    isSelected (value) {
      console.log(value)
      return value === this.selectedStatusId
    },
    showPopupChangeStatusOrder (data) {
      this.driverId = data.id
      this.$bvModal.show('changeStatus')
    }
  },
  created () {
    this.$root.$on('showPopupChangeStatusOrder', this.showPopupChangeStatusOrder)
  }
}
</script>
